<template>
  <div class="about">
    <HeroImage :bg-image="'images/5-about-1-hero.jpg'" :has-purple-bg="false">
      <div class="hp-search-wrap">
        <h1 class="title is-1 has-text-centered">No somos un laboratorio.<br>Somos los laboratorios.</h1>
      </div>
    </HeroImage>
    <section id="why-us">
      <div class="container is-fullhd">
        <div class="columns">
          <div class="column is-8 is-offset-2">
            <p class="is-size-4 has-text-centered pb-6">
              Fundada en Madrid, el objetivo de STLab es ofrecer a la clínica dental el laboratorio perfecto según sus necesidades. La era digital es la clave del éxito, y en odontología nos permite romper la barrera local, abriéndonos un mundo de posibilidades. Nosotros te ayudamos a encontrarlas.
            </p>
          </div>
        </div>
        <div class="columns">
          <div class="column is-half">
            <div class="circle-image">
              <img alt="stlab network" src="../assets/images/5-about-2-detail.png">
            </div>
          </div>
          <div class="column is-4 is-offset-1">
            <h2 class="title extra-jumbo-title txt-c-mobile">¿Porque STlab?</h2>
            <p class="pb-5 txt-c-mobile">Sencillo e intuitivo. Desde subir tu archivo hasta obtener la mejor asistencia durante tu pedido. Podrás seleccionar los laboratorios que mejor se adapten a tus necesidades, sin preocuparte de nada más ¡Del resto nos encargamos nosotros!</p>
            <button class="button is-medium is-primary">Empezar un pedido</button>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <h2 class="title jumbo-title txt-c-mobile">
              ¿Porque quedarse al margen cuando puedes formar parte de una comunidad exitosa?
            </h2>
            <p class="pb-5 txt-c-mobile">Si eres un laboratorio, únete a nuestra plataforma y expande tu red de clientes en toda España. STLab se encargará de todo, desde el pago hasta la logística. Las clínicas solicitarán tus servicios. ¡¡Solo tendrás que realizaras!!</p>
            <button class="button is-medium is-primary">Explorar los laboratorios</button>
          </div>
          <div class="column is-half">
            <div class="circle-image shift-left">
              <img alt="stlab Why us" src="../assets/images/5-about-3-detail.png">
            </div>
          </div>
        </div>
      </div>
    </section>
    <PreFooterCta></PreFooterCta>
    <Footer></Footer>
  </div>
</template>

<script>
import HeroImage from '@/components/HeroImage.vue'
import PreFooterCta from '@/components/PreFooterCta.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'About',
  components: {
    HeroImage,
    PreFooterCta,
    Footer
  },
  methods: {
    goTo (path) {
      this.$router.push({ path: path })
    }
  }
}
</script>

<style scoped lang="scss">
.about{
  width: 100%;
  overflow: hidden;
  section{
    padding: 60px 0px;
    .extra-jumbo-title{
      font-size: 5rem;
      padding-top: 100px;
    }
    .jumbo-title{
      font-size: 4rem;
      padding-top: 100px;
    }
    .circle-image{
      position: relative;
      width: 100%;
      z-index: -1;
      left: -80px;
      &.shift-left{
        width: 120%;
        left: -120px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #why-us{
    padding: 60px 21px;
    .extra-jumbo-title{
      padding-top: 21px;
      font-size: 3rem;
    }
    .jumbo-title{
      font-size: 1.8rem;
    }
    .circle-image{
      left: -150px;
      &.shift-left{
        width: 120%;
        left: initial;
        top: -100px;
        right: -150px;
      }
    }
    button{
      width: 100%;
    }
  }
}
</style>
